<template>
<v-app>

    <div>
      <form class="form" @submit.stop.prevent="formOnsubmit()">
        <!--begin: Wizard Step 1-->
        <div
          class="pb-5"
          data-wizard-type="step-content"
          data-wizard-state="current"
        >
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            <span>
              {{ title }}
            </span>
          </h5>

          <!-- Input Modal Trigger -->
        <!-- <b-form-group
          id="input-group-trigger-modal-patient"
          label="Pilih Ibu:"
          label-for="input-trigger-modal-patient"
        >
          <b-form-input
            id="input-trigger-modal-patient"
            v-model="display.mother_name"
            placeholder="Pilih Ibu"
            readonly
            @click="$bvModal.show('modal-patient')"
          >
          </b-form-input>
          <small class="text-danger">{{ error.mother_id }}</small>
        </b-form-group> -->

         
          <b-form-group
            id="input-group-name"
            label="Nama:"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="form.name"
              placeholder="Nama"
            ></b-form-input>
            <small class="text-danger">{{ error.name }}</small>
          </b-form-group>

          <!-- Input Doctor -->
          <b-form-group
            id="input-group-doctor"
            label="Jenis Kelamin:"
            label-for="input-doctor"
          >
            <b-form-select v-model="form.gender" :options="options"></b-form-select>
            <small class="text-danger">{{ error.gender }}</small>
          </b-form-group>
          

          <b-form-group
            id="input-group-name"
            label="Berat Lahir (Kg):"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="form.weight"
              placeholder="Berat Lahir"
            ></b-form-input>
            <small class="text-danger">{{ error.weight }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-name"
            label="Panjang Lahir (Cm):"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="form.height"
              placeholder="Panjang Lahir"
            ></b-form-input>
            <small class="text-danger">{{ error.height }}</small>
          </b-form-group>
          
          <b-form-group
            id="input-group-name"
          >
          <label for="input-address">Tanggal Lahir:</label>
            <v-dialog
                ref="dialog"
                v-model="modal3"
                :return-value.sync="form.date"
                persistent
                width="350px"
              >
                <template v-slot:activator="{ on, attrs }">

                  <b-form-input
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    id="input-trigger-modal-patient"
                    v-model="form.born_date"
                    placeholder="Tanggal Lahir"
                  >
                  </b-form-input>
                </template>

                <v-date-picker
                  v-if="modal3"
                  v-model="form.born_date"
                  locale="id"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="modal3 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(form.born_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            <small class="text-danger">{{ error.born_date }}</small>
          </b-form-group>

          <b-form-group
            id="input-group-name"
          >
          <label for="input-address">Tanggal Meninggal: <em class="text-muted">opsional</em></label>
            <v-dialog
                ref="dialog1"
                v-model="modal2"
                :return-value.sync="form.date"
                persistent
                width="350px"
              >
                <template v-slot:activator="{ on, attrs }">

                  <b-form-input
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    id="input-trigger-modal-patient"
                    v-model="form.die_date"
                    placeholder="Tanggal Meninggal"
                  >
                  </b-form-input>
                </template>

                <v-date-picker
                  v-if="modal2"
                  v-model="form.die_date"
                  locale="id"
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="modal2 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog1.save(form.die_date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            <small class="text-danger">{{ error.die_date }}</small>
          </b-form-group>

          <b-form-group id="input-group-address">
            <label for="input-address">Keterangan: <em class="text-muted">opsional</em></label>
            <b-form-textarea
              id="input-address"
              v-model="form.description"
              placeholder="Keterangan"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.description}}</small>
          </b-form-group>

          <!-- <b-form-group
            id="input-group-phone"
            label="Telepon:"
            label-for="input-phone"
          >
            <b-form-input
              id="input-phone"
              v-model="form.phone"
              placeholder="Telepon"
            ></b-form-input>
            <small class="text-danger">{{ error.phone }}</small>
          </b-form-group>

          Text input
          <b-form-group
            id="input-group-password"
            label="Password:"
            label-for="input-password"
            v-if="purpose == 'add'"
          >
            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  @click="
                    passwordIsVisible == 'password'
                      ? (passwordIsVisible = 'text')
                      : (passwordIsVisible = 'password')
                  "
                >
                  <div v-if="passwordIsVisible == 'password'">
                    <b-icon-eye-slash></b-icon-eye-slash>
                  </div>
                  <div v-if="passwordIsVisible == 'text'">
                    <b-icon-eye></b-icon-eye>
                  </div>
                </b-input-group-text>
              </template>
              <b-form-input
                id="input-password"
                v-model="form.password"
                placeholder="Password"
                :type="passwordIsVisible"
              ></b-form-input>
            </b-input-group>
            <small class="text-danger">{{ error.password }}</small>
          </b-form-group> -->
        </div>
        <!--end: Wizard Step 1-->

        <!--begin: Wizard Actions -->
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="$router.push('/mothers')"
            >
              Batal
            </b-button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
    </div>

    <!-- modal -->

      <b-modal
        id="modal-patient"
        size="xl"
        title="Pilih Ibu"
        hide-footer
      >
              <Table
                purpose="modal"
                @chooseMother="setMother"
              />
      </b-modal>

</v-app>
</template>

<script>
import Table from '@/view/components/mothers/Table.vue'
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import Treeselect from '@riophae/vue-treeselect'
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components:{
    Treeselect,
    Table
  },
  props: {
    form: Object,
    route: String,
    title: String,
    type: String,
  },
  data() {
    return {
      display:{
        mother_name:"",
        mother_id:"",
      },
      error: {
        name:"",
        gender:"",
        weigth:"",
        height:"",
        born_date:null,
        die_date:null,
        description:"",
      },
      bloodType:[],
      current_photo: "/img/default/default-user.svg",
      default_photo: "/img/default/default-user.svg",
      majors: [],
      // other
      passwordIsVisible: "password",
      // date
      time: null,
      menu2: false,
      modal2: false,
      modal3: false,
      picker: new Date().toISOString().substr(0, 10),
      // select
      options: [
          { value: null, text: 'Pilih jenis kelamin',disabled:true },
          { value: 'male', text: 'Laki-laki' },
          { value: 'female', text: 'Perempuan' },
          
        ]

    };
  },
  methods: {

    async setMother(value){
      if(this.type == ""){
        console.log(value,'value')
        this.form.mother_id = value.id
        this.display.mother_name = value.name
        this.display.mother_id = value.id
      }else{
         let response = await module.get('api/mothers/'+ this.form.mother_id);
         this.display.mother_name = response.name
         this.display.mother_id = this.form.mother_id
      }
    },

   
    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
          
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        console.log('push=============', `/mothers/detail/'${this.form.mother_id}`)
        this.$router.push('/mothers/detail/'+this.form.mother_id)
      }
    },
  },
  mounted() {
    this.setMother()
  },
  computed: {},
  watch: {},
};
</script>